<template>
  <pre class="app-code"><code v-html="highlighted"></code></pre>
</template>

<script>

export default {
  props: {
    code: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      validator: (v) => ['json'].indexOf(v) !== -1,
    },
  },
  data() {
    return {
      highlighted: '',
    };
  },
  mounted() {
    this.highlighted = this.highlight(this.code);
  },
  methods: {
    highlight(code) {
      let highlighting = code;
      switch (this.language) {
        case 'json':
          highlighting = highlighting.replace(/: (true|false)/g, ': <span class="json-boolean">$1</span>');
          highlighting = highlighting.replace(/: (null|undefined)/g, ': <span class="json-null">$1</span>');
          highlighting = highlighting.replace(/: ([0-9])+/g, ': <span class="json-number">$1</span>');
          highlighting = highlighting.replace(/: "(.*)"/g, ': <span class="json-text">"$1"</span>');
          highlighting = highlighting.replace(/"(.*)":/g, '<span class="json-key">"$1"</span>:');
          return highlighting;
        default:
          break;
      }
      return null;
    },
  },
};
</script>

<style lang="sass">
.app-code
  background-color: $primary100
  border-radius: $global-border-radius
  padding: 1.75rem
  margin: 0

  code
    @include code
    color: $white
    font-size: $font-3xs
    display: block
    overflow: scroll
    @include scrollbars

  .json
    &-boolean
      color: $green
    &-null
      color: $warning
    &-number
      color: $primary
    &-text
      color: $accent100
    &-key
      color: $primary20
</style>
