<!--
****************************************************************************************************
***                                          Table                                               ***
****************************************************************************************************

*************
*** PROPS ***
*************

* headers : [Object] - liste d'objets décrivant les en-têtes des colonnes
Le champ "label" est celui qui sera utilisé pour l'affichage
Le champ "key" peut différer selon l'utilisation.
  - Si vous ne comptez pas personnaliser l'affichage des cases de la colonne, donc ne pas spécifier de slot. Alors le champ key doit correspondre à une propriété de l'objet passé en props "data". De cette façon le component affichera automatiquement la valeur de la propriété dans la case, dans une balise <p>
  - Maintenant si vous comptez personnaliser l'affichage des cases de la colonne OU si votre propriété est nested (sous-objet). Alors le champ key servira seulement à retrouver la colonne lors de la spécification du template. Il doit donc être unique mais pas forcément correspondre à une propriété de l'objet en props "data"
Le champ "size" permet de définir la taille de la colonne (exemple: small-1, medium-5). Il est définit par défaut à "auto".

Exemple:

[
  {
    label: 'Object ID',
    key: 'objectId', //  Ici, on affichera la propriété "objectId" de l'objet
    size: 'medium-5',
  },
  {
    label: 'Color',
    key: 'color', //  Ici, on affichera la propriété "color" de l'objet
  },
  {
    label: 'Digit',
    key: 'metadata.pagination.page', // Ici, vu que les sous-objets ne sont pas pris en compte, il servira juste pour le retrouver dans les slots
  },
  {
    label: 'Bool',
    key: 'bool', //  Ici, on affichera la propriété "bool" de l'objet
    size: 'small-1',
  }
]

* data : [Object] - liste d'objets à afficher

Exemple:

[
  {
    objectId: '1',
    color: 'red',
    bool: true,
    metadata: {
      pagination: {
        page: 10,
      },
    },
  },
  {
    objectId: '2',
    color: 'blue',
    bool: true,
    metadata: {
      pagination: {
        page: 22,
      },
    },
  },
  {
    objectId: '3',
    color: 'yellow',
    bool: false,
    metadata: {
      pagination: {
        page: 57,
      },
    },
  }
]

* loading : Boolean - sert à changer l'affichage du tableau par le contenu passé dans le slot "loading"

*************
*** SLOTS ***
*************

Doc sur les scope-slots
- https://fr.vuejs.org/v2/guide/components-slots.html#Slots-avec-portee
- https://www.digitalocean.com/community/tutorials/vuejs-scoped-component-slots

Il est possible de personnaliser plusieurs éléments du tableau grâce aux "scope-slots". On peut indiquer une balise "template" pour personnaliser l'affichage des cases d'une colonne, sinon on garde l'affichage par défaut qui est un simple <p>

* header - Avec ce slot on indique quel format auront les cases de header. L'objet "data" nous donne accès aux champs définis dans la liste "headers" en props.
  NOTE : si ce slot n'est pas spécifié, alors le champ "label" de l'objet passé en props "headers" sera affiché dans une balise <label>

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="header" slot-scope="{ data }">
      <p><strong>{{ data.label }}</strong></p>
    </template>
  </app-table>

* CLE_CHAMP - Alors pour ce slot, le nom dépend du champ "key" que vous avez défini dans la liste "headers" en props. L'objet "data" nous donne accès aux objets passés en props "data"
  NOTE : si ce slot n'est pas spécifié pour une colonne, alors la propriété définie par le champ "key" du header de l'objet sera affiché dans une balise <p>

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="objectId" slot-scope="{ data }">
      <p><strong>{{ data.objectId }}</strong></p>
    </template>

    <template slot="metadata.pagination.page" slot-scope="{ data }">
      <p><strong>{{ data.metadata.pagination.page }}</strong></p>
    </template>
  </app-table>

* empty-table - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque la liste "data" en props est vide. On aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="empty-table">
      <p>Oups, c'est vide !</p>
    </template>
  </app-table>

* loading - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque le props "loading" est à true. De la même manière que pour le slot "empty-table", on aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="loading">
      <p>LOADING...</p>
    </template>
  </app-table>
-->

<template>
  <section class="app-table">
    <div class="grid-x header">
      <div
        v-for="header in headers"
        :key="header.key"
        class="cell"
        :class="header.size || 'auto' ">
        <slot v-if="$scopedSlots.header" name="header" :data="header"></slot>
        <label v-else>{{ header.label }}</label>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <slot name="loading"></slot>
    </div>

    <div v-else-if="data && data.length > 0 && !isDropdown" class="grid-x data">
      <div
        v-for="(line, index) in data"
        :key="index"
        :class="['line', { 'line-lighter': lighter }]"
      >
        <div class="grid-x">
          <div
            v-for="header in headers"
            :key="`${index}-${header.key}`"
            class="cell"
            :class="header.size || 'auto' ">
            <!-- Affichage custom si le slot est spécifié -->
            <slot
              v-if="$scopedSlots[header.key]"
              :name="header.key"
              :data="line"
            ></slot>

            <!-- Affichage par défaut, juste du texte -->
            <p v-else>
              {{ line[header.key] }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="data && data.length > 0 && isDropdown" class="grid-x data">
      <details
        v-for="(line, index) in data"
        :key="index"
        :class="['line', { 'line-lighter': lighter }]"
      >
        <summary class="grid-x table-dropdown">
          <div
            v-for="header in headers"
            :key="`${index}-${header.key}`"
            class="cell"
            :class="header.size || 'auto' "
          >
            <!-- Affichage custom si le slot est spécifié -->
            <slot
              v-if="$scopedSlots[header.key]"
              :name="header.key"
              :data="line"
            />

            <!-- Affichage par défaut, juste du texte -->
            <p v-else>
              {{ line[header.key] }}
            </p>
          </div>
        </summary>
        <div class="dropdown-open">
          <!-- Affichage custom si le slot est spécifié -->
          <slot
            v-if="$scopedSlots['dropdown']"
            name='dropdown'
            :data="line"
          />

          <!-- Affichage par défaut, juste du texte -->
          <p v-else>
            {{ line['dropdown'] }}
          </p>
        </div>
      </details>
    </div>

    <div v-else class="empty-table">
      <slot name="empty-table" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'app-table',
  props: {
    headers: [Array],
    // Format [{ label: 'Lorem Ipsum', key: 'lorem' }]
    data: [Array],
    loading: [Boolean],
    lighter: {
      type: Boolean,
      default: false,
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-table
  margin: 24px 0
  width: 100%
  .loading
    display: flex
    align-items: center
    justify-content: center
    padding-top: 2rem
  .header
    display: flex
    column-gap: 0.5rem
    padding: 1rem
    background-color: transparent
    div
      @include title-small
      color: $primary20
      display: flex
      justify-content: flex-start
      align-items: center
      button
        padding: 0
  .table-dropdown::-webkit-details-marker
    display: none
  .table-dropdown
    cursor: pointer
    column-gap: 0.5rem
    > div
      height: 60px
      &:last-child
        justify-content: flex-end !important
  .dropdown-open
    padding: 32px 24px
  .data
    .line
      display: flex
      flex-direction: row
      align-items: center
      width: 100%
      padding: 0.5rem 1rem
      margin-bottom: 8px
      background-color: $primary80
      border-radius: 0.5rem
      text-align: left
      &.line-lighter
        background-color: $primary70 !important
      .grid-x
        width: 100%
        .cell
          display: flex
          justify-content: flex-start
          align-items: center
          p
            @include label
            color: $white
  .empty-table
    padding: 3rem
    text-align: center
    p
      color: $white
</style>
