<!--
****************************************************************************************************
***                                          Radio                                               ***
****************************************************************************************************

Le composant radio est proche de celui en HTML5, c'est à dire une variable qui peut prendre 1 valeur.

*************
*** PROPS ***
*************
  - Value :
    C'est la valeur que prendra la variable dans v-model

    Exemple :
     <app-radio value="value1" v-model="answer">Label 1</app-radio>
     <app-radio value="value2" v-model="answer">Label 2</app-radio>
     <app-radio value="value3" v-model="answer">Label 3</app-radio>

-->
<template>
  <div class="app-radio">
      <label>
        <input type="radio"
          :checked="input === value"
          :disabled="disabled"
          @change="onChange()"/>
        <span class="checkmark" :class="{checked: input === value }">
          <checked class="checked-icon" v-if="input === value"/>
        </span>
        <slot></slot>
      </label>
  </div>
</template>

<script>
import checked from '@/assets/img/checked.svg?inline';

export default {
  components: {
    checked,
  },
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Array, Number, Object],
    value: {
      type: String,
    },
    disabled: Boolean,
  },
  data() {
    return {
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="sass">
.app-radio
  display: inline-block
  label
    display: inline-block
    position: relative
    margin: 0 5px
    cursor: pointer
    user-select: none

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:disabled + .checkmark
        opacity: 0.5
        cursor: not-allowed

    .checkmark
      display: inline-block
      height: 24px
      width: 24px
      border: 1px solid $primary40
      border-radius: 50%
      transform: translateY(5px)
      .checked-icon
        position: absolute
        top: 6px
        left: 4px

    .checked
      border-color: $primary
      background-color: $primary

</style>
