<!--
****************************************************************************************************
***                                         Input                                               ***
****************************************************************************************************

Champ texte simple ou multiligne

*************
*** PROPS ***
*************
  - type :
    Type du champ

  Exemple :
  <app-input type="text" v-model="answer4"/>
  <app-input type="textarea" v-model="answer4"/>

-->
<template>
  <div class="app-input" :class="{'validation-error': errorValidation}">
    <textarea v-if="type === 'textarea'" contenteditable="true"
      ref="textarea"
      :value="value"
      :required="required"
      @input="$emit('input', $event.target.value)"
      @invalid.prevent="handleInvalid">
      </textarea>
    <input v-else
      :type="appliedType"
      ref="input"
      :value="value"
      :required="required"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :inputmode="inputmode"
      :maxlength="maxlength"
      :minlength="minlength"
      :pattern="pattern"
      :tabindex="tabindex"
      :id="id"
      @input="$emit('input', $event.target.value)"
      @invalid.prevent="handleInvalid"/>
    <button
    type="button"
    v-if="type==='password'"
    class="icon-container"
    :class="{ visible: isPasswordVisible }"
    @click="isPasswordVisible = !isPasswordVisible"
    :title="isPasswordVisible ? 'Hide password' : 'Show password'"
    aria-label="Show password"
    :aria-pressed="isPasswordVisible">
      <SvgEye aria-hidden="true"/>
    </button>
    <p class="error" v-if="errorValidation">{{ errorValidation }}</p>
  </div>
</template>

<script>
import SvgEye from '@/assets/img/eye-closed.svg?inline';

export default {
  components: {
    SvgEye,
  },
  props: {
    value: [String, Array, Number],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password', 'textarea'].indexOf(val) !== -1,
    },
    autocomplete: {
      default: 'off',
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      default: 'text',
      validator: (val) => ['text', 'none', 'decimal', 'numeric', 'tel', 'email', 'search', 'url'].indexOf(val) !== -1,
    },
    id: {
      type: String,
      required: false,
    },
    minlength: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      errorValidation: null,
      isPasswordVisible: false,
    };
  },
  computed: {
    input() {
      return this.value;
    },
    elementRef() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
    appliedType() {
      if (this.type === 'password') {
        return this.isPasswordVisible ? 'text' : 'password';
      }
      return this.type;
    },
  },
  watch: {
    value() {
      this.checkCustomValidation();
    },
  },
  methods: {
    handleInvalid(e) {
      this.errorValidation = e.target.validationMessage;
    },
    checkCustomValidation() {
      this.errorValidation = null;
      if (this.$refs[this.elementRef].value) {
        this.$refs[this.elementRef].setCustomValidity('');
      }
    },
  },
};
</script>

<style lang="sass">
.app-input
  position: relative

  &.validation-error
    textarea, input
      border-color: $warning
  textarea, input
    width: 100%
    padding: 0.7rem 1rem 0.6rem
    background: $primary70
    font-size: $global-font-size
    border-radius: $global-border-radius
    border: 1px solid $primary70
    transition: all 0.2s ease-in-out
    color: $white
    &:not([disabled=disabled]):hover
      background-color: $primary50
    &:focus
      outline: 0
      border-color: $primary
    &[disabled=disabled]
      color: $primary30

  .icon-container
    position: absolute
    border-radius: 40px
    width: 32px
    height: 32px
    right: 7px
    top: 4px
    padding: 8px
    border: none
    background: none
    svg
      width: 16px
      height: 16px
    &:hover
      outline: none
      cursor: pointer
      background-color: rgba(255, 255, 255, .15)
    &:focus
      outline: 1px solid $primary
    &.visible
      path:last-child
        display: none

  .error
    color: $warning
    padding-top: 10px
</style>
