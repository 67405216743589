<template>
  <section class="activate">
    <img class="background-image" src="@/assets/img/background-login.jpeg" />
    <div class="grid-x">
      <div class="cell auto">
        <div class="logo-header">
          <logo />
        </div>
      </div>
      <div class="cell shrink">
      </div>
    </div>

    <div class="grid-x activate-container">
      <div class="cell auto illustration-container">
        <img src="@/assets/img/password.png"/>
      </div>
      <div class="cell auto form-container">
        <form ref="register">
          <div>
            <h1>Account activated</h1>
          </div>
          <div>
            <p>Your account is now activated ! You can complete your profile by following the onboarding</p>
          </div>
          <div>
            <router-link :to="{ name: 'onboarding', params: { step : 1 } }">
              <app-button size="large">Go to onboarding</app-button>
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import userApi from '@/services/api/user';
import authApi from '@/services/auth';

import logo from '@/assets/img/logo.svg?inline';

export default {
  name: 'activate',
  components: {
    logo,
  },
  mounted() {
    this.activate();
  },
  methods: {
    async activate() {
      if (this.$route.query.token) {
        try {
          const response = await userApi.activate(this.$route.query.token);
          await authApi.loginFromToken(response.token);
          this.$message.show({
            title: 'Welcome!',
            text: 'Your account is now active!',
            confirmText: 'Ok',
            hasCancel: false,
          });
        } catch (error) {
          throw error;
        }
      }
    },
  },
};
</script>

<style lang="sass">
.activate
  @include page
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  padding-top: 20px
  z-index: 0
  overflow: hidden
  background-color: $primary100
  .grid-x
    width: 100%
  .activate-container
    min-height: calc(100vh - 170px)
    justify-content: center
  h1
    color: $white
  .google-link button
    background-color: white
    color: black
    display: flex
    justify-content: center
    align-items: center
    svg
      width: 16px
      margin-right: 10px
  .background-image
    position: absolute
    z-index: -1
    top: 0
    left: 0
    width: 50%
    height: 100%
  .link
    @include link
    color: white
    text-decoration: underline
    padding-top: 5px
    display: block
  .logo-header
    svg
      width: 110px
  .illustration-container
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 70%
      margin-top: 50px
  .form-container
    display: flex
    align-items: center
    justify-content: center
  form
    width: 100%
    margin: 0 70px
    max-width: 400px
    > div
      padding: 1rem 0
  .logo-container
    margin-top: 50px
    display: flex
    align-items: center
    justify-content: center
    align-self: center
    svg
      width: 200px
  .back-link
    display: flex
    align-items: center
    justify-content: center
    a
      color: $primary20
      text-align: center
      text-decoration: underline
</style>
