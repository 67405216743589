<template>
  <label class="app-search" title="Search" role="search" aria-label="search">
    <input
      type="search"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <span>
      <search />
    </span>
  </label>
</template>

<script>
import search from '@/assets/img/search.svg?inline';

export default {
  name: 'app-search',
  components: {
    search,
  },
  props: {
    value: [String, Array, Number],
    placeholder: {
      type: String,
      default: 'Search...',
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang="sass">
.app-search
  width: 100%
  position: relative
  span
    position: absolute
    top: 50%
    left: 1.5rem
    transform: translate(-50%, -50%)
    width: 1.5rem
    height: 1.5rem
    display: flex
    justify-content: center
    align-items: center
  input[type=search]
    width: 100%
    border: 1px solid $primary50
    border-radius: $global-border-radius
    background-color: transparent
    color: $white
    outline: none
    font-size: $font-xxs
    padding: 11.5px 1rem 11.5px 3rem
    transition: all 0.3s ease-in-out
    &::-webkit-search-cancel-button
      -webkit-appearance: none
      height: 1em
      width: 1em
      background: url(../../assets/img/cross.svg) no-repeat 50% 50%
      background-size: contain
      opacity: 0
      cursor: pointer
      pointer-events: none
    &:focus
      border-color: $primary
      transition: all 0.3s ease-in-out
      &::-webkit-search-cancel-button
        opacity: 1
        pointer-events: all
    &:hover
      background: $primary80
</style>
