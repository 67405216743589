import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import dayjs from 'dayjs';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import auth from './services/auth';

import Basics from './basics/index';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
});

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

Vue.use(Basics);

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
};
Vue.use(StripePlugin, options);

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.isLoggedIn()) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

Vue.prototype.$dayjs = dayjs;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
