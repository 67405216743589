<!--
****************************************************************************************************
***                                         Label                                                ***
****************************************************************************************************

Label de formulaire

*************
*** PROPS ***
*************
  - required :
    indication du mandatory (*)

  Exemple :
  <app-label>Prénom<app-label/>

-->
<template>
  <label class="app-label" :class="{ mandatory: required }">
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-label
  display: block
  padding-bottom: 0.5rem
  text-align: left
  @include title-small
  color: $primary40

  &.mandatory:after
    content: "*"
    display: inline-block
    transform: translateX(0.1rem) translateY(0rem)
</style>
