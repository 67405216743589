import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Register member
const register = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, {
      firstname: user.firstname,
      lastname: user.lastname,
      address: user.address,
      email: user.email,
      password: user.password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const activate = async (token) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register/activate?token=${token}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/me`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const update = async (user) => {
  try {
    const response = await axios.put(`${API_URL}/dashboard/me`, {
      firstname: user.firstname,
      lastname: user.lastname,
      address: user.address,
      company: user.company,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const updatePassword = async (oldPassword, newPassword) => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/change-password`, {
      oldPassword,
      newPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteOne = async () => {
  try {
    const response = await axios.delete(`${API_URL}/dashboard/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const generateKey = async () => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/keys/generate`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.register = register;
api.activate = activate;
api.getMe = getMe;
api.update = update;
api.updatePassword = updatePassword;
api.delete = deleteOne;
api.generateKey = generateKey;

export default api;
