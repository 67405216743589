<template>
  <section id="app">
    <div class="grid-x">
      <!-- SIDE-NAV -->
      <div class="cell shrink nav-container" v-if="!hideSideBar">
        <side-nav />
      </div>

      <!-- CONTENT -->
      <div class="cell auto page-container">
        <!-- TODO : vérifier si on est sur un plan payant -->
        <div v-if="showCardError && !hideSideBar" class="card-alert error-card-alert">
          <p>
            <strong>Impossible to find your credit card informations.</strong> Please
            <router-link :to="{ name: 'card' }">check your credit card</router-link> informations.
          </p>
        </div>
        <top-nav v-if="!hideSideBar" :me="me" />
        <router-view
          :me="me"
          @updateCardInformation="updateCardInformation"
          :isCardActive="isCardActive"
        />
      </div>
    </div>

    <!-- GLOBAL BASICS COMPONENTS INTEGRATION -->
    <app-message />
    <app-notifications />
  </section>
</template>

<script>
import stripeApi from '@/services/api/stripe';
import userApi from '@/services/api/user';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import auth from '@/services/auth';

export default {
  name: 'app',

  components: {
    SideNav,
    TopNav,
  },

  data() {
    return {
      isLoggedIn: false,
      isCardActive: true,
      me: null,
      showCardError: false,
    };
  },

  mounted() {
    this.init();
  },

  watch: {
    $route() {
      this.isLoggedIn = auth.isLoggedIn();

      if (this.isLoggedIn) {
        this.getMe();
      } else {
        this.me = null;
      }
    },
  },

  computed: {
    hideSideBar() {
      return this.$route.meta.hideSideBar;
    },
  },

  methods: {
    init() {
      this.isLoggedIn = auth.isLoggedIn();

      if (this.isLoggedIn && !this.hideSideBar) {
        this.getCards();
      }
    },

    /**
     * On vérifie les informations de la carte à 3 moments :
     * • Au démarrage de l'app
     * • Au login
     * • Lorsqu'on se trouve sur la page des cartes
     *
     * L'objet peut contenir :
     * • une propriété cards avec la liste des cartes
     * • une propriété error si la récupération des cartes a échoué
     *
     * @param {object} data liste des cartes trouvées
     */
    updateCardInformation(data) {
      // Reset l'état actuel
      this.showCardError = false;

      if (data && data.error) {
        this.showCardError = true;
      } else if (data && data.cards) {
        this.isCardActive = data.cards && !!data.cards.data.length;
      } else {
        this.getCards();
      }
    },

    async getMe() {
      try {
        this.me = await userApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to find user',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Récupération des informations de la carte
    async getCards() {
      try {
        const cards = await stripeApi.getCards();
        this.isCardActive = cards && !!cards.data.length;
      } catch (er) {
        this.showCardError = true;
      }
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/sass/app.sass"

#app
  .page-container
    position: relative
    padding: 0
    background-color: $primary95

  .nav-container
    position: relative
    background-color: $primary100

  .card-alert
    width: 100%
    padding: 12px

    p, a
      text-align: center
      color: white

    a
      text-decoration: underline
      @include title-xsmall

  .error-card-alert
    background-color: $warning

  .no-card-alert
    background-color: $primary
</style>
