<!--
****************************************************************************************************
***                                       Datepicker                                             ***
****************************************************************************************************

Composant datepicker, configuré par défaut en fr

*************
*** PROPS ***
*************

  - placeholder :
    texte affiché dans le champ de saisie avant de choisir une date
  - insideText :
    texte affiché dans le champ de saisie avec la date sélectionnée

  Exemple :
  <app-datepicker v-model="answer5"/>

-->
<template>
  <datepicker
    class="app-datepicker"
    v-model="date"
    @selected="onSelect"
    :format="'text' ? customFormatter : 'dd/MM/yyyy'"
    :placeholder="placeholder"
    :language="languages['en']"
    clearButton
  />
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';

export default {
  components: {
    Datepicker,
  },

  props: {
    value: [Date],
    placeholder: [String],
    insideText: [String],
  },

  data() {
    return {
      languages: lang,
      date: null,
    };
  },

  watch: {
    value(data) {
      this.date = data;
    },
  },

  methods: {
    onSelect(value) {
      this.$emit('input', value);
    },

    customFormatter() {
      return `${this.insideText} ${this.$dayjs(this.date).format('DD/MM/YYYY')}`;
    },
  },
};
</script>

<style lang="sass">
.app-datepicker
  position: relative
  display: inline-block
  width: 100%
  max-width: 400px
  input
    width: 100%
    padding: 11.5px 1rem 11.5px 1rem
    background: transparent
    font-size: $global-font-size
    border-radius: 4px
    border: 1px solid $primary50
    transition: all 0.2s ease-in-out
    color: $white
    &:hover
      background: $primary80
    &:focus
      outline: 0
      border-color: $primary
  .vdp-datepicker__calendar
    right: 0
    margin-top: 4px
    background: $primary70
    color: $white
    border-radius: 8px
    border: 1px solid $primary50
    header
      .up
        &:hover
          background: $primary80!important
      .prev
        &:hover
          background: $primary80!important
        &:after
          border-right: 10px solid $white!important
      .next
        &:hover
          background: $primary80!important
        &:after
          border-left: 10px solid $white!important
    .day:not(.blank), .month:not(.blank), .year:not(.blank)
      &:hover
        border: 1px solid $primary!important
        border-radius: $global-border-radius

  .vdp-datepicker__calendar .cell.selected
    background: $primary!important
    border-radius: $global-border-radius
    font-weight: bold
  .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)
    right: 0rem
    span
      font-style: normal
      color: $primary20
      font-size: 2rem
</style>
