<!--
****************************************************************************************************
***                                    Pagination                                                ***
****************************************************************************************************

Composant pagination, il suppose agir sur le paramètre query de la page en cours,
mais une prop :route serait tout à fait envisageable aussi.

*************
*** PROPS ***
*************
  - offset :
    nombre d'éléments décalés

  - limit :
    nombre d'éléments par page

  - count :
    nombre d'éléments total

  Exemple :
  <app-pagination :offset="50" :limit="10" :count="300"/>

-->
<template>
  <nav class="app-pagination">
    <p>{{ offset + 1 }}-{{ Math.min(offset + limit, count) }} sur {{ count }}</p>
    <ul>
      <li>
        <button :disabled="offset === 0" @click="firstPage">
          <fast-forward />
        </button>
      </li>
      <li>
        <button :disabled="offset === 0" @click="previousPage">
          <forward />
        </button>
      </li>
      <li>
        <button class="forward" :disabled="offset + limit + 1 > count" @click="nextPage">
          <forward />
        </button>
      </li>
      <li>
        <button class="fast-forward" :disabled="offset + limit + 1 > count" @click="lastPage">
          <fast-forward />
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import forward from '@/assets/img/forward.svg?inline';
import fastForward from '@/assets/img/fast-forward.svg?inline';

export default {
  name: 'app-pagination',
  components: {
    forward,
    fastForward,
  },
  props: {
    offset: {
      type: Number,
    },
    limit: {
      type: Number,
    },
    count: {
      type: Number,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    if (!this.$route.query.page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
        },
      });
    }
  },
  methods: {
    firstPage() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
        },
      });
    },
    previousPage() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: parseInt(this.$route.query.page, 10) - 1,
        },
      });
    },
    nextPage() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: parseInt(this.$route.query.page, 10) + 1,
        },
      });
    },
    lastPage() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: Math.max(1, Math.ceil(this.count / this.limit)),
        },
      });
    },
  },
};
</script>

<style lang="sass">
.app-pagination
  display: flex
  flex-direction: row
  align-items: center
  column-gap: 1.25rem

  ul
    display: flex
    flex-direction: row
    align-content: center
    column-gap: 1rem
    list-style: none
    padding: 0
    margin: 0

  button
    position: relative
    cursor: pointer
    text-decoration: underline
    display: flex
    align-items: center
    background-color: transparent
    border: none
    padding: 0
    margin 0

    &:disabled
      cursor: not-allowed
      opacity: 0.5
      pointer-events: none

    &:not(:disabled)
      &::after
        content: ""
        position: absolute
        width: 100%
        height: 1px
        background-color: $primary20
        bottom: -0.1rem
        left: 0
        transition: all 0.2s ease-in-out

      &:hover
        &::after
          opacity: 0
          transition: all 0.2s ease-in-out

  .forward,
  .fast-forward
    transform: rotate(180deg)

    &::after
      top: -0.1rem
      bottom: unset
</style>
