<template>
  <section class="login">
    <div class="grid-x header">
      <div class="cell auto">
        <div class="logo-header">
          <a href="https://doppio.sh/">
            <logo />
          </a>
        </div>
      </div>
      <div class="cell shrink">
        <div class="logo-header">
          <router-link :to="{ name: 'register' }" class="link">Don't have an account?</router-link>
        </div>
      </div>
    </div>

    <div class="grid-x content">
      <div class="cell auto illustration-container">
        <video autoplay loop muted width="100%" height="auto">
          <source src="doppio.webm" type="video/webm; codecs='vp8, vorbis'"/>
          <source src="doppio.mp4" type="video/mp4"/>
          The real tiny Doppio factory that creates one by one PDF documents from HTML files
        </video>
      </div>
      <div class="cell auto form-container">
        <div class="logo-container">
          <logo />
        </div>
        <form ref="register" @submit.prevent="login(user)">
          <div class="input-email">
            <app-label required for="email">Email</app-label>
            <app-input
              autocomplete="email"
              type="email"
              inputmode="email"
              :maxlength="128"
              required
              id="email"
              v-model="user.email"
            />
          </div>
          <div class="input-password">
            <app-label required for="password">Password</app-label>
            <app-input
              autocomplete="current-password"
              type="password"
              required
              :minlength="8"
              :maxlength="64"
              id="password"
              v-model="user.password"
            />
          </div>
          <div class="forgot-password">
            <router-link :to="{ name: 'reset-password' }" class="link">Forgot password?</router-link>
          </div>
          <div class="btn-login">
            <app-button type="submit" size="large" :loading="isLoading">Login</app-button>
          </div>
          <div class="btn-login-google">
            <a :href="googleLoginUrl" class="google-link">
              <app-button size="large"><google-icon />Login with Google</app-button>
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';
import userApi from '@/services/api/user';

import logo from '@/assets/img/logo.svg?inline';
import googleIcon from '@/assets/img/google.svg?inline';

export default {
  name: 'login',

  components: {
    logo,
    'google-icon': googleIcon,
  },

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
      googleLoginUrl: `${process.env.VUE_APP_API_URL}/auth/google`,
    };
  },

  watch: {
    async $route() {
      await this.ssoLogin();
    },
  },

  async mounted() {
    await this.ssoLogin();
  },

  methods: {
    async ssoLogin() {
      if (this.$route.query.token) {
        try {
          // On ajoute le token
          await auth.loginFromToken(this.$route.query.token);

          // On fait une requête pour vérifier que l'url est bien toujours valide
          const user = await userApi.getMe();

          if (user && user.userId) {
            if (this.$route.query.isRegister === 'true') {
              this.$router.push({ name: 'onboarding', params: { step: 1 } });
            } else {
              this.$router.push({ name: 'home' });
            }
          }

          // On déclenche la récupération des informations de la carte
          this.$emit('updateCardInformation');
        } catch (er) {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to connect to your account',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }
    },

    async login(user) {
      if (this.$refs.register.checkValidity()) {
        this.isLoading = true;

        try {
          await auth.login(user);

          this.$notification.show({
            text: 'Login successful !',
          });

          this.$router.push({ name: 'home' });

          this.$emit('updateCardInformation', true);
        } catch (error) {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to connect to your account',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.login

  @media (max-width: 767px)
    overflow-x: hidden
    padding: 0

  @include page
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  padding: 40px 50px
  z-index: 0
  background-color: $primary100
  overflow: auto

  .grid-x
    width: 100%

  h1
    color: $white

  video
    height: 80vh
    max-height: 600px

  .google-link button
    background-color: white !important
    color: black
    display: flex
    justify-content: center
    align-items: center

    svg
      width: 16px
      margin-right: 10px

      path
        stroke: none

  .link
    color: white
    text-decoration: underline

    &:hover
      text-decoration: none

  .logo-header
    svg
      width: 110px

  .illustration-container
    display: flex
    align-items: center
    justify-content: center
    padding-top: 50px
    min-height: calc(100vh - 170px)

  .form-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

  form
    width: 100%
    margin: 0 70px
    max-width: 400px

    .input-password
      margin-bottom: 16px

    .input-email,
    .btn-login
      margin-bottom: 32px

    .forgot-password
      margin-bottom: 48px

  .logo-container
    margin-bottom: 48px
    display: flex
    align-items: center
    justify-content: center
    align-self: center

    svg
      width: 200px

  @media (max-width: 767px)
    .header
      padding: 16px 24px

    .content
      flex-direction: column
      height: 100%
      padding: 25px

      .illustration-container
        padding: 0
        min-height: auto

      form
        margin: 0 25px

      .app-button
        margin: 0

      .logo-container
        display: none
</style>
