import axios from 'axios';
import ls from 'local-storage';

const auth = {};

// Utilisation de require pour contourner un bug de webpack
const router = require('../router');

const API_URL = process.env.VUE_APP_API_URL;
let TOKEN = ls('TOKEN') || false;
const redirectRouteName = 'login';

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
}

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    auth.logout();

    if (router.default.currentRoute.name === redirectRouteName && ['Wrong username/password combination'].includes(error.response.data.message)) {
      return Promise.reject(error);
    }
    router.default.push({ name: redirectRouteName });
    return new Promise(() => {});
  }

  return Promise.reject(error);
});

// Récupération du token
const getToken = () => ls('TOKEN');

// L'utilisateur est-il loggué
const isLoggedIn = () => !!getToken();

// Login
const login = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email: user.email,
      password: user.password,
    });

    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;

    return response.data;
  } catch (error) {
    throw error;
  }
};

// reset password
const sendResetPassword = async (email) => {
  try {
    await axios.post(`${API_URL}/auth/reset-password`, {
      email,
    });

    return true;
  } catch (error) {
    throw error;
  }
};

// reset password
const resetPassword = async (token, password) => {
  try {
    await axios.post(`${API_URL}/auth/reset-password/new?token=${token}`, {
      password,
    });

    return true;
  } catch (error) {
    throw error;
  }
};

// Login
const loginFromToken = async (token) => {
  try {
    TOKEN = token;
    ls('TOKEN', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    return true;
  } catch (error) {
    throw error;
  }
};

// Logout
const logout = () => {
  ls.remove('TOKEN');
  ls.remove('STATUS');
  delete axios.defaults.headers.common.Authorization;
};

// Authorization Header
const getAuthorizationHeader = () => axios.defaults.headers.common.Authorization;

auth.login = login;
auth.isLoggedIn = isLoggedIn;
auth.logout = logout;
auth.getToken = getToken;
auth.getAuthorizationHeader = getAuthorizationHeader;
auth.loginFromToken = loginFromToken;
auth.resetPassword = resetPassword;
auth.sendResetPassword = sendResetPassword;

export default auth;
