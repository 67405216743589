<!--
****************************************************************************************************
***                                      Message                                                 ***
****************************************************************************************************

  Modale de message avec possibilité de répondre (proche du confirm javascript)

  Peut être appelé dans n'importe quel composant avec une fonction.

  Exemple :
  this.$message.show({
    title: 'Lorem',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero aperiam repellendus officiis optio eum error cupiditate ad explicabo eveniet!',
    onConfirm: () => {
      this.closeModal();
    },
  });

-->
<template>
  <div>
    <transition name="message">
    <div class="message-wrapper" v-if="visible">
      <h2>{{ title }}</h2>
      <p>{{ text }}</p>
      <div class="message-buttons">
        <app-button v-if="hasCancel" look="tertiary" @click="hide">{{ cancelText }}</app-button>
        <app-button @click="confirm" :look="confirmLook">{{ confirmText }}</app-button>
      </div>
    </div>
    </transition>
    <transition name="screen">
      <div class="message-screen" v-if="visible" @click="hide()"></div>
    </transition>
  </div>
</template>

<script>
import AppEvent from '../utils/AppEvent';

export default {
  data() {
    return {
      visible: false,
      title: '',
      text: '',
      confirmText: 'Ok',
      cancelText: 'Cancel',
      hasCancel: true,
      confirmLook: 'primarySquare',

      onConfirm: {},
    };
  },
  methods: {
    hide() {
      this.visible = false;
      document.documentElement.style.overflow = 'auto';
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm();
        this.hide();
      } else {
        this.hide();
      }
    },
    show(params) {
      this.visible = true;
      document.documentElement.style.overflow = 'hidden';

      this.title = params.title;
      this.text = params.text;

      this.onConfirm = params.onConfirm || {};
      this.confirmText = params.confirmText || 'Ok';
      this.cancelText = params.cancelText || 'Cancel';
      this.confirmLook = params.confirmLook || 'primarySquare';
      this.hasCancel = !!params.hasCancel;
    },
  },
  beforeMount() {
    AppEvent.$on('app-message-show', (params) => {
      this.show(params);
    });
  },
  beforeDestroy() {
    AppEvent.$off('app-message-show', (params) => {
      this.show(params);
    });
    document.documentElement.style.overflow = 'auto';
  },
};
</script>

<style lang="sass">
.message-wrapper
  position: fixed
  top: 50%
  left: 50%
  width: 400px
  border-radius: $global-border-radius
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.42), 0 1px 2px rgba(0, 0, 0, 0.64)
  background: $primary95
  z-index: 1000
  transform: translate(-50%, -50%)
  h2
    color: $white
    margin-bottom: 20px
    margin: 40px 50px 10px 50px
  p
    @include label
    color: $primary20
    margin: 24px 50px
    white-space: pre-wrap

.message-screen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0,0,0,0.7)
  z-index: 998

.message-buttons
  display: flex
  justify-content: flex-end
  gap: 1.4rem
  margin-top: 3rem
  border-top: $primary40 1px solid
  padding: 30px

.screen-enter-active, .screen-leave-active
  transition: opacity .9s

.screen-enter, .screen-leave-to
  opacity: 0

.message-enter-active, .message-leave-active
  transition: transform .5s, opacity .3s

.message-enter, .message-leave-to
  opacity: 0
  transform: translateY(-130px) translateX(-50%)

</style>
