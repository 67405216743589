<!--
****************************************************************************************************
***                                      Collapse                                                ***
****************************************************************************************************

Collapse

*************
*** PROPS ***
*************

  Exemple :
  <app-collapse v-model="activeTab" :name="1" title="Lorem 1">
    Lorem Ipsum
  <app-collapse/>

  <app-collapse v-model="activeTab" :name="2" title="Lorem 2">
    Lorem Ipsum
  <app-collapse/>

  <app-collapse v-model="activeTab" :name="3" title="Lorem 3">
    Lorem Ipsum
  <app-collapse/>

-->
<template>
  <div class="app-collapse" :class="{ open: input === name }">
    <div class="header" @click="onChange()">
      <div class="title">{{ title }}</div>
      <arrow class="arrow"/>
    </div>
    <div class="content" ref="content" :style="{ height: input === name ? `${$refs.content.scrollHeight}px` : 0 }">
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
import arrow from '@/assets/img/app-collapse-arrow.svg?inline';

export default {
  components: {
    arrow,
  },
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Number],
    name: [String],
    title: [String],
  },
  methods: {
    onChange() {
      this.$emit('input', this.name === this.input ? null : this.name);
    },
  },
};
</script>

<style lang="sass">
.app-collapse
  .header
    padding: 24px
    background: $primary90
    border-radius: 8px
    display: flex
    justify-content: space-between
    transition: all 0.1s linear
    transition-delay: 0.3s
    cursor: pointer
    font-family: 'Inter', sans-serif
    font-weight: 600
    line-height: 22px
    .title
      flex: 1
    .arrow
      margin-left: 20px
      transition: all 0.2s linear
  .content
    background: $primary90
    overflow: hidden
    transition: all 0.3s linear
    border-radius: 0 0 8px 8px
    > div
      padding: 0 24px 24px
      font-family: 'Inter', sans-serif
      color: $primary30
  &.open
    .header
      border-radius: 8px 8px 0 0
      transition-delay: 0s
      .arrow
        transform: rotate(-180deg)
</style>
