import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/views/account/Login.vue';
import Activate from '@/views/account/Activate.vue';
import NewPassword from '@/views/account/NewPassword.vue';

const Register = () => import('@/views/account/Register.vue');
const ResetPassword = () => import('@/views/account/ResetPassword.vue');
const OnBoarding = () => import('@/views/account/OnBoarding.vue');
const Cards = () => import('@/views/cards/Cards.vue');
const Invoices = () => import('@/views/invoices/Invoices.vue');
const Plans = () => import('@/views/plans/Plans.vue');
const Card = () => import('@/views/cards/Card.vue');
const Logs = () => import('@/views/logs/Logs.vue');
const Keys = () => import('@/views/keys/Keys.vue');
const Support = () => import('@/views/support/Support.vue');
const Account = () => import('@/views/account/Account.vue');
const Overview = () => import('@/views/overview/Overview.vue');
const Templates = () => import('@/views/templates/Templates.vue');
const FormTemplate = () => import('@/views/templates/FormTemplate.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'landing',
    //   component: Landing,
    //   meta: {
    //     title: 'HTML to PDF API | HTML to PNG API - Doppio.sh',
    //     description:
    //       'HTML to PDF API : Generate beautiful PDF documents or PNG screenshots with an API and store them directly in your own S3 bucket without compromising privacy with a managed Puppeteer',
    //     hideSideBar: true,
    //   },
    // },
    {
      path: '/overview',
      name: 'home',
      component: Overview,
      meta: {
        title: 'Overview',
        icon: 'overview',
        description: '',
        requiresAuth: true,
      },
    },
    // ACCOUNT
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        title: 'Account',
        description: '',
        icon: 'account',
        requiresAuth: true,
      },
    },
    {
      path: '/onboarding/:step',
      name: 'onboarding',
      component: OnBoarding,
      meta: {
        title: 'Onboarding',
        description: '',
        icon: 'onboarding',
        requiresAuth: true,
        hideSideBar: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Log in to your account and check your dashboard',
        description: 'Log in to your account and check your dashboard PDF logs',
        hideSideBar: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        title: 'Register your account and start generating PDFs',
        description: 'Create your account and start to generate PDF',
        hideSideBar: true,
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        title: 'Reset your password',
        description: 'Reset your password',
        hideSideBar: true,
      },
    },
    {
      path: '/reset-password/new',
      name: 'reset-password-new',
      component: NewPassword,
      meta: {
        title: 'Reset your password',
        description: 'Reset your password',
        hideSideBar: true,
      },
    },
    {
      path: '/activate-account',
      name: 'activate-account',
      component: Activate,
      meta: {
        title: 'Activate your account',
        description: 'Activate your account',
        hideSideBar: true,
      },
    },
    // BILLING
    {
      path: '/billing/plans',
      name: 'plans',
      component: Plans,
      meta: {
        title: 'Billing',
        description: '',
        icon: 'billing',
        requiresAuth: true,
      },
    },
    {
      path: '/billing/invoices',
      name: 'invoices',
      component: Invoices,
      meta: {
        title: 'Billing',
        description: '',
        icon: 'billing',
        requiresAuth: true,
      },
    },
    {
      path: '/billing/cards',
      name: 'cards',
      component: Cards,
      meta: {
        title: 'Billing',
        description: '',
        icon: 'billing',
        requiresAuth: true,
      },
    },
    {
      path: '/billing/card',
      name: 'card',
      component: Card,
      meta: {
        title: 'Add a credit card',
        description: '',
        icon: 'billing',
        requiresAuth: true,
      },
    },
    // API KEYS
    {
      path: '/api-keys',
      name: 'api-keys',
      component: Keys,
      meta: {
        title: 'API Keys',
        description: '',
        icon: 'keys',
        requiresAuth: true,
      },
    },
    // SUPPORT
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        title: 'Support',
        description: '',
        icon: 'support',
        requiresAuth: true,
      },
    },
    // LOGS
    {
      path: '/logs',
      name: 'logs',
      component: Logs,
      meta: {
        title: 'Logs',
        description: '',
        icon: 'logs',
        requiresAuth: true,
      },
    },
    // TEMPLATES
    {
      path: '/templates',
      name: 'templates',
      component: Templates,
      meta: {
        title: 'Templates',
        description: '',
        icon: 'templates',
        requiresAuth: true,
      },
    },
    {
      path: '/templates/create',
      name: 'create-template',
      component: FormTemplate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/templates/:templateId',
      name: 'update-template',
      component: FormTemplate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '*',
      redirect: '/login',
    },
  ],
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta && to.meta.title && to.meta.description) {
      document.title = `${to.meta.title} - Doppio`;
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', to.meta.description);
    }
  });
});

export default router;
