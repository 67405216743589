<template>
  <div class="radial-progress-container">
    <div class="radial-progress-inner">
      <div class="document-count-container">
        <label class="document-count" :class="{ 'document-count-small': completedSteps >= 100000 }">
          {{ stepsDisplay || '--' }}
        </label>
        <label class="document-max-count">/ {{ maxDocuments || '--' }}</label>
      </div>
      <slot/>
    </div>

    <svg
      class="radial-progress-bar"
      :width="diameter"
      :height="diameter / 2"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        :r="innerCircleRadius"
        :cx="radius"
        :cy="radius"
        fill="transparent"
        :stroke="innerStrokeColor"
        :stroke-dasharray="circumference"
        stroke-dashoffset="0"
        :style="strokeStyle"/>
      <circle
        id="circle"
        :transform="`rotate(180, ${radius} , ${radius})`"
        :r="innerCircleRadius"
        :cx="radius"
        :cy="radius"
        fill="transparent"
        :stroke="strokeColor"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference"
        :style="progressStyle"/>
    </svg>
  </div>
</template>

<script>
import utils from '../../services/utils/utils';

export default {
  props: {
    diameter: {
      type: Number,
      required: false,
      default: 200,
    },

    totalSteps: {
      type: Number,
      required: false,
      default: 400,
    },

    completedSteps: {
      type: Number,
      required: false,
      default: 0,
    },

    strokeColor: {
      type: String,
      required: false,
      default: '#E345FF',
    },

    strokeWidth: {
      type: Number,
      required: false,
      default: 7,
    },

    animateSpeed: {
      type: Number,
      required: false,
      default: 1000,
    },

    innerStrokeColor: {
      type: String,
      required: false,
      default: '#3D3758',
    },

    fps: {
      type: Number,
      required: false,
      default: 60,
    },

    timingFunc: {
      type: String,
      required: false,
      default: 'ease-in-out',
    },
  },

  data() {
    return {
      gradientAnimation: null,
      currentAngle: 0,
      strokeDashoffset: 0,
      point: 0,
    };
  },

  created() {
    this.changeProgress();
  },

  watch: {
    totalSteps() {
      this.changeProgress();
    },

    completedSteps() {
      this.changeProgress();
    },

    diameter() {
      this.changeProgress();
    },

    strokeWidth() {
      this.changeProgress();
    },
  },

  computed: {
    stepsDisplay() {
      return utils.formatNumber(Math.trunc(this.easeOut * this.completedSteps));
    },

    maxDocuments() {
      return utils.formatNumber(this.totalSteps);
    },

    radius() {
      return this.diameter / 2;
    },

    circumference() {
      return Math.PI * this.innerCircleDiameter;
    },

    stepSize() {
      if (this.totalSteps === 0) {
        return 0;
      }

      return 50 / this.totalSteps;
    },

    finishedPercentage() {
      return this.stepSize * this.completedSteps;
    },

    circleSlice() {
      return (2 * Math.PI) / this.totalSteps;
    },

    animateSlice() {
      return this.circleSlice / this.totalPoints;
    },

    innerCircleDiameter() {
      return this.diameter - (this.strokeWidth * 2);
    },

    innerCircleRadius() {
      return this.innerCircleDiameter / 2;
    },

    totalPoints() {
      return (this.animateSpeed * this.fps) / 1000;
    },

    animationIncrements() {
      return 1000 / this.fps;
    },

    progressStyle() {
      return {
        height: `${this.diameter / 2}px`,
        width: `${this.diameter}px`,
        strokeWidth: `${this.strokeWidth}px`,
        strokeDashoffset: this.strokeDashoffset,
      };
    },

    strokeStyle() {
      return {
        height: `${this.diameter}px`,
        width: `${this.diameter}px`,
        strokeWidth: `${this.strokeWidth}px`,
      };
    },

    easeOut() {
      return 1 - (1 - (Math.min(this.point, this.totalPoints) / this.totalPoints)) ** 4;
    },

    finalDashOffset() {
      return ((-this.finishedPercentage) / 100) * this.circumference;
    },
  },

  methods: {
    changeProgress() {
      this.strokeDashoffset = this.circumference;

      if (this.gradientAnimation) {
        clearInterval(this.gradientAnimation);
      }

      const angleOffset = (this.completedSteps - 1) * this.circleSlice;
      let i = (this.currentAngle - angleOffset) / this.animateSlice;
      const incrementer = Math.abs(i - this.totalPoints) / this.totalPoints;
      const isMoveForward = i < this.totalPoints;

      this.gradientAnimation = setInterval(() => {
        if ((isMoveForward && i >= this.totalPoints) || (!isMoveForward && i < this.totalPoints)) {
          this.strokeDashoffset = this.finalDashOffset + this.circumference;
          this.point = this.totalPoints;

          clearInterval(this.gradientAnimation);

          return;
        }

        this.currentAngle = angleOffset + (this.animateSlice * i);
        this.strokeDashoffset = this.circumference + this.finalDashOffset * this.easeOut;
        this.point += 1;
        i += isMoveForward ? incrementer : -incrementer;
      }, this.animationIncrements);
    },
  },
};
</script>

<style lang="sass">
.radial-progress-container
  position: relative
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center

  .radial-progress-bar
    transform: scale(1, 0.913)

  .radial-progress-inner
    width: 100%
    height: auto
    position: absolute
    top: 50%
    left: 0
    right: 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 8px
    z-index: 1

    .document-count-container
      display: flex
      align-items: center
      justify-content: center

      .document-count, .document-max-count
        color: $white
        font-weight: 500
        font-size: 16px
        line-height: 20px

      .document-count
        font-size: 40px
        text-align: right

        &.document-count-small
          font-size: 35px

      .document-max-count
        margin-left: 8px
        font-size: 20px
        font-weight: 400

</style>
