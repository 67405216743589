import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Récupération des cartes de crédit
const getCards = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/cards`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Suppression d'une carte de crédit
const deleteCard = async (cardId) => {
  try {
    const response = await axios.delete(`${API_URL}/dashboard/cards/${cardId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Récupération de la carte de crédit par défaut
const getDefaultCard = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/cards/default`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

// Modification de la carte de crédit par défaut
const setDefaultCard = async (cardId) => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/cards/${cardId}/set-default`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération de la liste des factures de l'utilisateur
 * @param {number} limit nombre de résultats à récupérer
 * @param {string} startDate date de début de la recherche
 * @param {string} endDate date de fin de la recherche
 * @param {string} startingAfter id de la facture après laquelle on récupère les résultats
 * @param {string} endingBefore id de la facture avant laquelle on récupère les résultats
 * @returns la liste des factures de l'utilisateur
 */
const getInvoices = async (limit, startDate, endDate, startingAfter, endingBefore) => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/invoices`, {
      params: {
        ...(limit && { limit }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
        ...(startingAfter && { startingAfter }),
        ...(endingBefore && { endingBefore }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère un setup intent dans le but de configurer une nouvelle carte de crédit
 */
const getSetupIntent = async () => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/cards/setup-intent`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getCards = getCards;
api.getInvoices = getInvoices;
api.deleteCard = deleteCard;
api.getDefaultCard = getDefaultCard;
api.setDefaultCard = setDefaultCard;
api.getSetupIntent = getSetupIntent;

export default api;
