<template>
  <nav class="side-nav">
    <div class="logo-header">
      <a href="https://doppio.sh/"><logo /></a>
    </div>

    <router-link class="item" :to="{ name: 'home' }"><overview-menu /> Overview</router-link>

    <router-link class="item" :to="{ name: 'api-keys' }"><keys-menu /> API key</router-link>

    <router-link class="item" :to="{ name: 'logs' }"><logs-menu /> Logs</router-link>

    <router-link class="item" :to="{ name: 'templates' }"><templates-menu /> Templates <span class="beta">beta</span></router-link>

    <router-link class="item" :to="{ name: 'plans' }"><billing-menu /> Billing</router-link>

    <router-link class="item" :to="{ name: 'support' }"><support-menu /> Support</router-link>

    <a class="item" target="_blank" :href="docUrl"><documentation-menu /> Documentation</a>
  </nav>
</template>

<script>
import logo from '@/assets/img/logo.svg?inline';
import overviewMenu from '@/assets/img/overview-menu.svg?inline';
import keysMenu from '@/assets/img/keys-menu.svg?inline';
import logsMenu from '@/assets/img/logs-menu.svg?inline';
import templatesMenu from '@/assets/img/templates-menu.svg?inline';
import billingMenu from '@/assets/img/billing-menu.svg?inline';
import documentationMenu from '@/assets/img/documentation-menu.svg?inline';
import supportMenu from '@/assets/img/support-menu.svg?inline';

export default {
  name: 'side-nav',
  components: {
    logo,
    'overview-menu': overviewMenu,
    'keys-menu': keysMenu,
    'billing-menu': billingMenu,
    'logs-menu': logsMenu,
    'documentation-menu': documentationMenu,
    'support-menu': supportMenu,
    'templates-menu': templatesMenu,
  },
  data() {
    return {
      docUrl: null,
    };
  },
  mounted() {
    this.docUrl = process.env.VUE_APP_DOC_URL;
  },
};
</script>

<style lang="sass">
.side-nav
  position: sticky
  top: 0
  min-height: 100vh
  padding: 2rem 0 0
  background: $primary100 linear-gradient(45deg, #1C1035 0%, #0C091B 50%)
  min-width: 250px

  .beta
    margin: -0.7rem 0 0 0.4rem
    padding: 0.2rem 0.5rem
    border-radius: 10px
    background-color: $accent100
    font-size: 0.7rem
    line-height: 1
    color: white

  .item
    @include nav

    display: flex
    align-items: center
    margin: 0 18px
    padding: 16px
    border-radius: 8px
    color: $white

    svg
      margin-right: 10px

    &.router-link-active
      background-color: $primary70

  .logo-header

    svg
      width: 130px
      margin: 20px 40px 40px 40px
</style>
