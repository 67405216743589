<template>
  <nav class="top-nav">
    <div class="grid-x">
      <div class="cell auto title-container">
        <h1>
          <overview v-if="$route.meta.icon === 'overview'" />
          <keys v-else-if="$route.meta.icon === 'keys'" />
          <logs v-else-if="$route.meta.icon === 'logs'" />
          <templates v-else-if="$route.meta.icon === 'templates'" />
          <billing v-else-if="$route.meta.icon === 'billing'" />
          <support v-else-if="$route.meta.icon === 'support'" />
          <account v-else-if="$route.meta.icon === 'account'" />
          <title-icon v-else />
          {{ $route.meta.title }}
        </h1>
      </div>
      <div class="cell shrink grid-x">
        <div v-if="me" class="cell shrink">
          <p class="plan">{{ PackageTypesLabel[me.package.type]}}</p>
          <router-link class="upgrade" :to="{ name: 'plans' }">Upgrade</router-link>
        </div>
        <div class="cell shrink grid-x">
          <div class="avatar cell">
            <p v-if="me && me.firstname[0] && me.lastname[0]">{{ me.firstname[0].toUpperCase() }}{{ me.lastname[0].toUpperCase() }}</p>
            <p v-else>--</p>
          </div>
          <div class="cell shrink arrow-container" @click="isOpen = !isOpen">
            <arrow class="arrow" :class="{ 'is-open': isOpen }"/>
          </div>
          <div class="account-menu" :class="{ 'is-open': isOpen }">
            <div class="item user-name">
              <p v-if="me">{{ me.firstname || '-' }} {{ me.lastname || '-' }}</p>
              <p v-else>--</p>
            </div>
            <router-link :to="{ name: 'account' }" class="item">
              <p><lock />Account</p>
            </router-link>
            <div class="item" @click="logout">
              <p><logout />Log out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import titleIcon from '@/assets/img/title-icon.svg?inline';
import overview from '@/assets/img/overview.svg?inline';
import keys from '@/assets/img/keys.svg?inline';
import logs from '@/assets/img/logs.svg?inline';
import templates from '@/assets/img/templates.svg?inline';
import billing from '@/assets/img/billing.svg?inline';
import support from '@/assets/img/support.svg?inline';
import account from '@/assets/img/account.svg?inline';
import arrow from '@/assets/img/arrow.svg?inline';
import logout from '@/assets/img/logout.svg?inline';
import lock from '@/assets/img/lock.svg?inline';
import auth from '@/services/auth';

import PackageTypesLabel from '@/enums/packageTypesLabel';

export default {
  name: 'top-nav',

  props: {
    me: {
      type: Object,
    },
  },

  components: {
    'title-icon': titleIcon,
    arrow,
    logout,
    lock,
    overview,
    keys,
    logs,
    templates,
    billing,
    support,
    account,
  },

  data() {
    return {
      isOpen: false,
      PackageTypesLabel,
    };
  },

  watch: {
    $route: 'resetMenu',
  },

  methods: {
    resetMenu() {
      this.isOpen = false;
    },

    async logout() {
      try {
        await auth.logout();

        this.$router.push({ name: 'login' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to disconnect user',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.top-nav
  padding: 1em
  background-color: $primary95
  border-bottom: $primary70 1px solid
  position: sticky
  top: 0
  z-index: 100
  .title-container
    display: flex
    align-items: center
    h1
      color: $white
      margin: 0
      padding-left: 35px
      display: flex
      align-items: center
      > svg
        width: 30px
        margin-right: 10px
  .plan
    @include nav
    color: $white
    margin: 0 2rem 0 0
  .upgrade
    color: $accent100
    text-decoration: underline
  .avatar
    display: flex
    justify-content: center
    align-items: center
    background-color: $primary50
    width: 43px
    border-radius: 50%
    user-select: none
    p
      @include title-large
      color: white
      margin: 0
  .arrow-container
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-right: 2.5rem
    .arrow
      width: 0.75rem
      margin: 0 0.5rem
      transition: transform 0.5s ease-out
      &.is-open
        transform: rotate(-180deg)
  .account-menu
    background-color: $primary70
    position: absolute
    top: calc(100% - 5px)
    width: 12rem
    min-width: fit-content
    right: 3.5rem
    border-radius: $global-border-radius
    overflow: hidden
    max-height: 0
    z-index: 10
    transition: max-height 0.3s ease-out
    box-shadow: 0px 10px 15px -3px $primary90
    &.is-open
      max-height: 200px
    .item
      padding: 12px 18px
      display: flex
      align-items: center
      transition: background-color 0.5s ease-out
      &.user-name
        border-bottom: 1px $primary50 solid
        p
          @include title-large
      &:not(.user-name)
        cursor: pointer
      p
        color: white
        display: flex
        align-items: center
        font-size: $font-xxs
      svg
        height: 20px
        margin-right: 10px
      &:hover:not(.user-name)
        background-color: darken($primary70, 3%)
</style>
