<!--
****************************************************************************************************
***                                           Tabs                                               ***
****************************************************************************************************

Composant de tabs, la gestion de tabs avec des routes est à priviligier.

*************
*** PROPS ***
*************
  - tabs
    C'est un tableau de propriétés pour les Tabs le format [{ label: 'Tab1', route: routeTab1 }]

    Exemple :
    <app-tab :tabs="[
      { label: 'Infos', route: { name: 'informations', params: { id: 453-324 } } },
      { label: 'Actions', route: { name: 'actions', params: { id: 453-324 } } }]" />

-->
<template>
  <div class="app-tabs">
    <router-link class="tab"
      v-for="tab in tabs"
      :key="`tab-${tab.label}`"
      :to="tab.route">
        <h4>{{ tab.label }}</h4>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="sass">
.app-tabs
  display: inline-flex
  align-items: center
  .tab
    background-color: $primary80
    padding: 8px 16px
    border-radius: 8px
    margin-right: 8px

    &.router-link-active
      background-color: $primary

    h4
      font-family: 'Outfit', sans-serif
      color: white

</style>
