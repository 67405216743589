<!--
****************************************************************************************************
***                                         Button                                               ***
****************************************************************************************************

Un bouton pour toutes les occasions.

*************
*** PROPS ***
*************
  - type : button ou submit
    Type du bouton

  - size : small ou medium ou large
    Taille du bouton

  - look : primary ou second
    Style du bouton

  - loading
    Présence du loader ou non

  Exemple :
  <app-button size="small" look="second" :loading="true">Bouton petit avec loader</app-button>
-->
<template>
  <button class="app-button" :type="type" :class="[size, look, effect]" @click="handleClick">
    <app-spinner v-if="loading" class="loader" :color="loaderColor" small />
    <slot v-else />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },

    size: {
      default: 'medium',
      validator: (val) => ['small', 'medium', 'large'].indexOf(val) !== -1,
    },

    look: {
      default: 'primarySquare',
      validator: (val) => [
        'primaryRound',
        'secondaryRound',
        'primarySquare',
        'secondarySquare',
        'tertiary',
        'warning',
        'ghost',
      ].indexOf(val) !== -1,
    },

    effect: {
      default: 'classic',
      validator: (val) => ['classic', 'gradient'].indexOf(val) !== -1,
    },

    loading: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    loaderColor() {
      if (this.look === 'primaryRound' || this.look === 'primarySquare') {
        return 'white';
      }

      if (this.look === 'warning') {
        return 'accent';
      }

      return 'primary';
    },
  },

  methods: {
    handleClick(evt) {
      if (!this.loading) {
        this.$emit('click', evt);
      }
    },
  },
};
</script>

<style lang="sass">
.app-button
  display: flex
  align-items: center
  justify-content: center
  padding: 12px 16px
  border: 0
  cursor: pointer
  color: $white
  @include button

  svg path
    stroke: currentcolor

  a
    color: inherit
    font-weight: inherit

  &.classic
    &:hover
      background: lighten($primary, 5%)

  &.primaryRound, &.primarySquare
    position: relative
    background: $primary
    z-index: 0
    outline: 1px solid transparent
    transition: all .2s ease-in-out

    &:focus
      outline-color: lighten($primary, 5%)

  &.gradient
    background: linear-gradient(90deg, $primary 50%, $accent100 100%) 0 0
    background-size: 200%
    transition: all .2s ease-in-out

    &:hover
      background-position: 100% 0
      color: $white

  &.secondaryRound
    background: $primary
    color: $white
    border: 1px solid $white
    outline: 1px solid transparent
    transition: all .2s ease-in-out
    &:disabled
      color: $primary140

    &:hover
      background: $primary
      border-color: $accent100
      color: $accent100

    &:focus
      outline-color: lighten($primary, 10%)

  &.secondarySquare
    background: #322D48
    color: #AAA0D4
    outline: 1px solid transparent
    transition: all .2s ease-in-out
    &:disabled
      color: $primary140

    &:hover
      background: lighten(#322D48, 5%)

    &:focus
      outline-color: #AAA0D4

  &.primaryRound, &.secondaryRound
    border-radius: 100px

  &.primarySquare, &.secondarySquare, &.ghost
    border-radius: $global-border-radius

  &.ghost
    color: $primary30
    background: transparent
    outline: 1px solid transparent
    transition: all .2s ease-in-out

    &:hover
      background-color: lighten($primary30, 35%)

    &:focus
      background-color: transparent
      outline-color: lighten($primary30, 35%)

  &.warning
    color: $accent100
    border: 1px solid $accent100
    background: transparent
    border-radius: $global-border-radius
    outline: 1px solid transparent
    transition: all .2s ease-in-out

    &:hover
      background-color: rgba(255,255,255,0.02)
      color: lighten($accent100, 5%)
      border-color: lighten($accent100, 5%)

    &:focus
      outline-color: lighten($accent100, 5%)

  &.tertiary
    color: $white
    border: 1px solid currentcolor
    background: transparent
    border-radius: $global-border-radius
    outline: 1px solid transparent
    transition: all .2s ease-in-out
    &:hover
      background: transparent
      color: $primary20
    &:focus
      border-width: 2px

  &:disabled
    cursor: not-allowed
    background: $primary70
    color: $primary40
    &:hover
      background: $primary50
    &:focus
      outline-color: $primary20

  &.small
    padding: 0.6rem 1.4rem 0.5rem
    font-size: 0.85rem

  &.large
    width: 100%

  @keyframes loader
    0%
      top: 0
      height: 180%

    50%, 100%
      top: 0
      height: 100%

</style>
